import { httpClientIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import getUIEnv from '../app/junkDrawer/getUIEnv.ts';
import { version } from '../app/version.ts';

const SENTRY_DSN =
  'https://732d5fd23d57b8f1ac573fd48196fc33@o4505863274496000.ingest.sentry.io/4505870011531264';

type SentryConfig = {
  environment: string;
  tracesSampleRate: number;
  replaysSessionSampleRate: number;
  disabled: boolean;
  debug: boolean;
};

const getDefaults = (): SentryConfig => {
  switch (getUIEnv()) {
    case 'local':
      // return {
      //   environment: 'local',
      //   tracesSampleRate: 1.0,
      //   replaysSessionSampleRate: 1.0,
      //   disabled: false,
      //   debug: false,
      // };
      return {
        environment: 'local',
        tracesSampleRate: 0.0,
        replaysSessionSampleRate: 0.0,
        disabled: true,
        debug: true,
      };
    case 'test':
      return {
        environment: 'test',
        tracesSampleRate: 1,
        replaysSessionSampleRate: 0.1,
        disabled: false,
        debug: false,
      };
    case 'development':
      return {
        environment: 'development',
        tracesSampleRate: 0.0,
        replaysSessionSampleRate: 0.1,
        disabled: false,
        debug: false,
      };
    case 'uat':
      return {
        environment: 'uat',
        tracesSampleRate: 1,
        replaysSessionSampleRate: 1,
        disabled: false,
        debug: false,
      };
    case 'production':
      return {
        environment: 'production',
        tracesSampleRate: 1,
        replaysSessionSampleRate: 0.1,
        disabled: false,
        debug: false,
      };
  }
};

const initializeSentry = () => {
  const settings = getDefaults();

  const isPlaywright = window.navigator.userAgent.includes('zeck-playwright');
  if (settings.disabled || isPlaywright) {
    console.log('Sentry disabled by user-agent or settings');
    return;
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    environment: settings.environment,
    release: version,
    enableTracing: true,
    sendDefaultPii: true,
    debug: settings.debug && !settings.disabled,
    integrations: [
      Sentry.browserTracingIntegration({
        tracePropagationTargets: [
          'localhost',
          '127.0.0.1',
          /^https:\/\/([a-z0-9-]+\.)*zeck\.app/,
        ],
      }),
      Sentry.replayIntegration({
        networkDetailAllowUrls: [
          'localhost',
          '127.0.0.1',
          /^https:\/\/([a-z0-9-]+\.)*zeck\.app/,
        ],
        networkResponseHeaders: ['Apigw-Requestid'],
        networkRequestHeaders: ['Apigw-Requestid'],
        networkCaptureBodies: false,
      }),
      Sentry.httpContextIntegration(),
      // captureConsoleIntegration(),
      httpClientIntegration(),
    ],
    tracesSampleRate: settings.tracesSampleRate,
    replaysSessionSampleRate: settings.replaysSessionSampleRate,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: [
      /Non-Error promise rejection captured with value: Object Not Found Matching Id:\d+, MethodName:\w*, ParamCount:\d+/,
    ],
  });
};

export default initializeSentry;
